import Vue from 'vue';
import Vuex from 'vuex';

import admins from './modules/admins';
import auth from './modules/auth';
import blacklist from './modules/blacklist';
import blog from './modules/blog';
import bonuses from './modules/bonuses';
import categories from './modules/categories';
import contacts from './modules/contacts';
import estimations from './modules/estimations';
import expendables from './modules/expendables';
import games from './modules/games';
import groups from './modules/groups';
import ingredients from './modules/ingredients';
import labels from './modules/labels';
import landings from './modules/landings';
import mailings from './modules/mailings';
import media from './modules/media';
import merchants from './modules/merchants';
import news from './modules/news';
import orders from './modules/orders';
import organizations from './modules/organizations';
import products from './modules/products';
import promocodes from './modules/promocodes';
import promos from './modules/promos';
import pushIcons from './modules/pushIcons';
import qa from './modules/qa';
import ratings from './modules/ratings';
import recommendations from './modules/recommendations';
import seo from './modules/seo';
import servicePushes from './modules/servicePushes';
import settings from './modules/settings';
import tags from './modules/tags';
import terminals from './modules/terminals';
import toasts from './modules/toasts';
import users from './modules/users';
import versions from './modules/versions';
import zones from './modules/zones';

Vue.use(Vuex);

// ['super-admin', 'admin', 'manager-delivery', 'operator-delivery', 'marketing', 'content-manager', 'guest']

export default new Vuex.Store({
  state: {
    roleAccess: {
      admins: {
        add: ['super-admin'],
        edit: ['super-admin'],
        remove: ['super-admin'],
        showAll: ['super-admin']
      },

      blog: {
        add: ['super-admin', 'admin', 'marketing'],
        edit: ['super-admin', 'admin', 'marketing'],
        showAll: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest'],
        showCard: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest'],
      },

      bonuses: {
        import: ['super-admin', 'admin', 'manager-delivery'],
        remove: ['super-admin', 'admin', 'marketing'],
        showAll: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest'],
        showCard: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest'],
      },

      contacts: {
        add: ['super-admin', 'admin', 'manager-delivery', 'marketing'],
        remove: ['super-admin', 'admin', 'manager-delivery', 'marketing'],
        showAll: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest'],
      },

      games: {
        add: ['super-admin', 'admin', 'marketing'],
        edit: ['super-admin', 'admin', 'marketing']
      },

      landings: {
        add: ['super-admin', 'admin', 'marketing'],
        edit: ['super-admin', 'admin', 'marketing'],
        remove: ['super-admin', 'admin', 'marketing'],
        showHistory: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest'],
        showSeo: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest'],
      },

      mailings: {
        add: ['super-admin', 'admin', 'marketing'],
        addIcon: ['super-admin', 'admin', 'manager-delivery'],
        edit: ['super-admin', 'admin', 'marketing'],
        removeIcon: ['super-admin', 'admin', 'manager-delivery'],
        send: ['super-admin', 'admin', 'marketing'],
        showAll: ['super-admin', 'admin', 'marketing', 'guest'],
        showCard: ['super-admin', 'admin', 'marketing', 'guest'],
        showIcon: ['super-admin', 'admin', 'manager-delivery'],
        showIcons: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest'],
      },

      media: {
        add: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest'],
        remove: ['super-admin', 'admin', 'manager-delivery'],
        showAll: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest'],
      },

      menu: {
        addCategory: ['super-admin', 'admin', 'manager-delivery'],
        addExpendable: ['super-admin', 'admin', 'manager-delivery'],
        addGroup: ['super-admin', 'admin', 'manager-delivery'],
        addIngredient: ['super-admin', 'admin', 'manager-delivery'],
        addLabel: 'all',
        addRecommendation: ['super-admin', 'admin', 'manager-delivery', 'marketing'],
        editGroupModifier: ['super-admin', 'admin', 'manager-delivery'],
        editProduct: ['super-admin', 'admin', 'manager-delivery'],
        import: ['super-admin', 'admin', 'manager-delivery'],
      },

      merchants: {
        add: ['super-admin', 'admin'],
        edit: ['super-admin', 'admin'],
        remove: ['super-admin', 'admin'],
        showAll: ['super-admin', 'admin']
      },

      news: {
        add: ['super-admin', 'admin', 'marketing'],
        edit: ['super-admin', 'admin', 'marketing'],
        showAll: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest'],
        showCard: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest'],
      },

      orders: {
        export: ['super-admin', 'admin', 'manager-delivery', 'marketing'],
        remove: 'all',
        showAll: 'all',
        showCard: 'all',
      },

      organizations: {
        add: ['super-admin', 'admin'],
        addIcon: ['super-admin', 'admin', 'marketing'],
        addTag: ['super-admin', 'admin', 'marketing'],
        edit: ['super-admin', 'admin'],
        editIcon: ['super-admin', 'admin', 'marketing'],
        editTag: ['super-admin', 'admin', 'marketing'],
        import: ['super-admin', 'admin', 'manager-delivery'],
      },

      promocodes: {
        add: ['super-admin', 'admin', 'marketing'],
        edit: ['super-admin', 'admin', 'marketing'],
        import: ['super-admin', 'admin', 'marketing']
      },

      promos: {
        add: ['super-admin', 'admin', 'marketing'],
        edit: ['super-admin', 'admin', 'marketing'],
        remove: ['super-admin', 'admin', 'marketing'],
        showAll: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest'],
        showCard: ['super-admin', 'admin', 'manager-delivery', 'marketing', 'guest']
      },

      reviews: {
        removeQa: ['super-admin', 'admin'],
      },

      settings: {
        edit: ['super-admin', 'admin'],
        showAll: ['super-admin', 'admin']
      },

      terminals: {
        add: ['super-admin', 'admin'],
        edit: ['super-admin', 'admin'],
        import: ['super-admin', 'admin', 'manager-delivery'],
        showAll: ['super-admin', 'admin', 'manager-delivery', 'guest'],
        showCard: ['super-admin', 'admin', 'manager-delivery', 'guest'],
      },

      users: {
        addToBlacklist: ['super-admin', 'admin'],
        block: ['super-admin', 'admin', 'manager-delivery'],
        edit: ['super-admin', 'admin', 'manager-delivery', 'marketing'],
        export: ['super-admin', 'admin', 'marketing'],
        loginAs: ['super-admin', 'admin', 'manager-delivery', 'operator-delivery'],
        remove: ['super-admin', 'admin', 'manager-delivery'],
        showAll: 'all',
        showCard: 'all',
        showBlacklist: ['super-admin', 'admin'],
        showOperators: 'all',
      },

      zones: {
        edit: ['super-admin', 'admin'],
        import: ['super-admin', 'admin', 'manager-delivery'],
        showAll: ['super-admin', 'admin', 'manager-delivery', 'guest']
      }
    }
  },

  actions: {},

  getters: {
    checkRoleAccess: (state, getters, rootState) => {
      const makeResultObject = function (items, initPath = '') {
        if (items instanceof Array) {
          return {
            [initPath]: items.includes(rootState.auth.role)
          };
        } else if (items === 'all') {
          return {
            [initPath]: true
          };
        }

        let resultObject = {};
        const path = initPath ? `${initPath}/` : '';

        for (let key in items) {
          Object.assign(resultObject, makeResultObject(items[key], `${path}${key}`));
        }

        return resultObject;
      };

      return makeResultObject(state.roleAccess);
    }
  },

  mutations: {},

  modules: {
    admins,
    auth,
    blacklist,
    blog,
    bonuses,
    categories,
    contacts,
    estimations,
    expendables,
    games,
    groups,
    ingredients,
    labels,
    landings,
    mailings,
    media,
    merchants,
    news,
    orders,
    organizations,
    products,
    promocodes,
    promos,
    pushIcons,
    qa,
    ratings,
    recommendations,
    seo,
    servicePushes,
    settings,
    tags,
    terminals,
    toasts,
    users,
    versions,
    zones
  }
});
