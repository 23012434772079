export default {
  state: {
    isLoggedin: false,
    login: '',
    role: '',
  },

  mutations: {
    INIT_USER(state, payload) {
      state.role = payload.roles[0].name;
      state.login = payload.login;
    },

    LOGOUT(state) {
      state.isLoggedin = false;
      state.login = '';
      state.role = '';
      // eslint-disable-next-line no-undef
      $cookies.remove('niyamaAdminAuthToken');
    },

    SET_USER_TOKEN(state, token) {
      // eslint-disable-next-line no-undef
      $cookies.set('niyamaAdminAuthToken', token);
      // eslint-disable-next-line no-undef
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      state.isLoggedin = true;
    }
  },

  actions: {
    async getInfo({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/get-info')
        .then((response) => {
console.log(response);
          commit('INIT_USER', response.data);
        });
    },

    async signIn({ commit }, payload) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/auth', payload)
        .then((response) => {
          commit('SET_USER_TOKEN', response.data.admin.token);
        });
    }
  },

  getters: {},

  namespaced: true,
};
