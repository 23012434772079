export default {
  state: {
    groups: [],
    groupsWithModifiers: [],
  },

  mutations: {
    SET_GROUPS(state, groups) {
      state.groups = groups;
    },

    SET_GROUPS_WITH_MODIFIERS(state, groups) {
      state.groupsWithModifiers = groups;
    },
  },

  actions: {
    async addGroup(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/nomenclature/groups/store', formData);
    },

    async editGroup(_, { id, formData }) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/nomenclature/groups/${id}/update`, formData);
    },

    async getGroupById(_, groupId) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(`/panel/nomenclature/groups/${groupId}`);

        return response.data.data;
      } catch (error) {
        throw error;
      }
    },

    async getGroups({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/nomenclature/groups')
        .then(response => {
          commit('SET_GROUPS', response.data.data);
        });
    },

    async getGroup({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_PER_PAGE_PARAM', 20);
        commit('SET_SEARCH_PHRASE_PARAM', '');
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.perPage !== undefined) {
          commit('SET_PER_PAGE_PARAM', payload.perPage);
        }

        if (typeof payload.searchPhrase === 'string') {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }
      }

      let url = '/panel/nomenclature/groups';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

       try {
      // eslint-disable-next-line no-undef
       const response = await axios.get(url);

        if (payload?.returnData) {
          return response.data;
        } else {
          commit('SET_GROUPS', response.data);
        }

      } catch (error) {
        throw error;
      }
    },

    async getGroupsWithModifiers({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/nomenclature/groups/with-modifiers')
        .then(response => {
          commit('SET_GROUPS_WITH_MODIFIERS', response.data.data);
        });
    },

    async getGroupWithModifiersById(_, groupId) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(`/panel/nomenclature/groups/with-modifiers/${groupId}`);

        return response.data.data;
      } catch (error) {
        throw error;
      }
    },

    async removeGroup(_, groupId) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/nomenclature/groups/${groupId}/delete`);
    }
  },

  getters: {},

  namespaced: true,
};
