export default {
  state: {
    products: [],
    selectedProduct: null,
    sortParams: {
      'groups[]': [],
      page: 1,
      search_name: '',
      sort_column: 'name',
      sort_dir: 'asc',
      type: 'dish'
    },
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_GROUPS_PARAM(state, groups) {
      state.sortParams['groups[]'] = groups;
    },

    SET_PRODUCTS(state, products) {
      state.products = products;
    },

    SET_SEARCH_PHRASE_PARAM(state, phrase) {
      state.sortParams.search_name = phrase;
    },

    SET_SELECTED_PRODUCT(state, product) {
      state.selectedProduct = product;
    },

    SET_SORT_COLUMN_PARAM(state, columnName) {
      state.sortParams.sort_column = columnName;
    },

    SET_SORT_DIRECTION_PARAM(state, sortDirection) {
      state.sortParams.sort_dir = sortDirection;
    },

    SET_TYPE_PARAM(state, type) {
      state.sortParams.type = type;
    }
  },

  actions: {
    async editProduct(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post(`/panel/nomenclature/products/${formData.get('id')}/update`, formData);
    },

    async getProductById({ commit }, productId) {
      // eslint-disable-next-line no-undef
      await axios.get(`/panel/nomenclature/products/${productId}`)
        .then((response) => {
          commit('SET_SELECTED_PRODUCT', response.data.data);
        });
    },

    async getProducts({ commit, state }, payload = null) {

console.log('getProducts');
console.log(payload);

      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_GROUPS_PARAM', []);
        commit('SET_SEARCH_PHRASE_PARAM', '');
        commit('SET_SORT_COLUMN_PARAM', 'name');
        commit('SET_SORT_DIRECTION_PARAM', 'asc');
        commit('SET_TYPE_PARAM', 'dish');
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.groups !== undefined) {
          commit('SET_GROUPS_PARAM', payload.groups);
        }

        if (payload.searchPhrase !== undefined) {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }

        if (payload.sortColumn !== undefined) {
          commit('SET_SORT_COLUMN_PARAM', payload.sortColumn);
        }

        if (payload.sortDirection !== undefined) {
          commit('SET_SORT_DIRECTION_PARAM', payload.sortDirection);
        }

        if (payload.type !== undefined) {
          commit('SET_SORT_DIRECTION_PARAM', payload.type);
        }
      }

      let url = '/panel/nomenclature/products';
console.log(state);
      for (let key in state.sortParams) {
        const value = state.sortParams[key];

       
        if (payload) { 
            if (payload.groups !== undefined) {
                if (!payload.groups) {
                    if (key == 'groups[]')
                        continue;
                }
            }
        }

        if (value) {
          if (value instanceof Array) {
            if (value.length) {
              value.forEach((item) => {
                url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${item}`;
              });
            }
          } else {
            url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${value}`;
          }
        }
      }
console.log(url);
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(url);

        if (payload?.returnData) {
          return response.data;
        } else {
          commit('SET_PRODUCTS', response.data);
        }
      } catch (error) {
        throw error;
      }
    },

    async importMenu(_, isWithImages) {
      let url = '/panel/nomenclature/import/import';

      if (isWithImages) {
        url += '?with-image';
      }

      // eslint-disable-next-line no-undef
      await axios.post(url);
    }
  },

  getters: {},

  namespaced: true,
};
