export default {
  state: {
    games: [],
    // sortParams: {
    //   page: 1,
    //   per_page: 20,
    //   search: '',
    // },
  },

  mutations: {
    // SET_CURRENT_PAGE_PARAM(state, currentPage) {
    //   state.sortParams.page = currentPage;
    // },

    SET_GAMES(state, games) {
      state.games = games;
    },

    // SET_PER_PAGE_PARAM(state, perPage) {
    //   state.sortParams.per_page = perPage;
    // },

    // SET_SEARCH_PHRASE_PARAM(state, phrase) {
    //   state.sortParams.search = phrase;
    // },
  },

  actions: {
    async addGame(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/games/store', formData);
    },

    async editGame(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/games/update', formData);
    },

    async getGames({ commit }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/panel/games');
        commit('SET_GAMES', response.data);
      } catch (error) {
        throw error;
      }

      // if (!payload) {
      //   commit('SET_CURRENT_PAGE_PARAM', 1);
      //   commit('SET_PER_PAGE_PARAM', 20);
      //   commit('SET_SEARCH_PHRASE_PARAM', '');
      // } else {
      //   if (payload.currentPage !== undefined) {
      //     commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
      //   }

      //   if (payload.perPage !== undefined) {
      //     commit('SET_PER_PAGE_PARAM', payload.perPage);
      //   }

      //   if (typeof payload.searchPhrase === 'string') {
      //     commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
      //   }
      // }

      // let url = '/panel/games';

      // for (let key in state.sortParams) {
      //   if (state.sortParams[key]) {
      //     url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
      //   }
      // }

      // try {
      //   // eslint-disable-next-line no-undef
      //   const response = await axios.get(url);

      //   if (payload?.returnData) {
      //     return response.data;
      //   } else {
      //     commit('SET_GAMES', response.data);
      //   }
      // } catch (error) {
      //   throw error;
      // }
    },

    async removeGame(_, gameId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/games/delete', {
        id: gameId
      });
    }
  },

  getters: {},

  namespaced: true,
};
