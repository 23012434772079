export default {
  state: {
    settings: [],
  },

  mutations: {
    SET_SETTINGS(state, settings) {
      state.settings = settings;
    }
  },

  actions: {
    async editSetting(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/settings/update', formData);
    },

    async getSettings({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/settings')
        .then(response => {
          commit('SET_SETTINGS', response.data);
        });
    },
  },

  getters: {},

  namespaced: true,
};

