export default {
  state: {
    mailings: [],
    users: [],
    usersSortParams: {
      page: 1,
      phrase: ''
    }
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.usersSortParams.page = currentPage;
    },

    SET_MAILINGS(state, mailings) {
      state.mailings = mailings;
    },

    SET_SEARCH_PHRASE_PARAM(state, phrase) {
      state.usersSortParams.phrase = phrase;
    },

    SET_USERS(state, users) {
      state.users = users;
    }
  },

  actions: {
    async addMailing(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/mailing-channels/store', formData);
    },

    async editMailing(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/mailing-channels/update', formData);
    },

    async getMailings({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/mailing-channels')
        .then(response => {
          commit('SET_MAILINGS', response.data);
        });
    },

    async getUsersForPushes({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_SEARCH_PHRASE_PARAM', '');
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.searchPhrase !== undefined) {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }
      }

      let url = '/panel/mailing-channels/push-users';

      for (let key in state.usersSortParams) {
        if (state.usersSortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.usersSortParams[key]}`;
        }
      }

      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(url);

        if (payload?.returnData) {
          return response.data;
        } else {
          commit('SET_USERS', response.data);
        }
      } catch (error) {
        throw error;
      }
    },

    async removeMailing(_, mailingId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/mailing-channels/delete', {
        id: mailingId
      });
    },

    async sendMailing(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/mailing-channels/send-mailing', formData);
    },

    async sendPush(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/mailing-channels/send-push', formData);
    },
  },

  getters: {},

  namespaced: true,
};
